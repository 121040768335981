<template>
  <div class="CommentMgrList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #searchSlot>
        <v-input label="评论内容" v-model="searchParams.msgContent"></v-input>
        <v-input label="评论人" v-model="searchParams.inuser" placeholder = "请输入完整姓名"></v-input>
        <v-datepicker :defaultTimeType="dataType" label="评论时间段" :maxDate="maxDate" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-select label="评论类型" v-model="searchParams.messageType" :options="messageTypeOps"></v-select>
        <v-input label="发帖人" v-model="searchParams.topicUserName" placeholder = "请输入完整姓名"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="viewDetail" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="trashDelete(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" permission="delete" @click="batchDelete(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, deleteURL, deleteBatchURL } from './api'
import { setStatusOps, messageType } from './map'
import { communityParams } from '@/common/select2Params'

export default {
  name: 'CommentMgrList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      messageTypeOps: setStatusOps(1),
      communityParams,
      maxDate: new Date(),
      searchParams: {
        msgContent: '',
        inuser: '',
        messageType: undefined,
        topicUserName: '',
        communityId: '',
        startTime: '',
        endTime: ''
      },
      dataType: '',
      headers: [
        {
          prop: 'msgContent',
          label: '评论内容'
        },
        {
          prop: 'userName',
          label: '评论人'
        },
        {
          prop: 'messageType',
          label: '评论类型',
          formatter (row) {
            return messageType[row.messageType]
          }
        },
        {
          prop: 'topicUserName',
          label: '发帖人'
        },
        {
          prop: 'intime',
          label: '评论时间'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        }
      ],
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  created () {
    let userType = this.$store.state.userInfo.userType
    this.dataType = userType !== '101' ? 'week' : ''
  },
  methods: {
    async trashDelete (row) {
      let isOk = await this.$confirm('确认删除？')
      isOk && this.delete(row)
    },
    delete (row) {
      let _this = this
      let postData = {
        id: row.id
      }
      _this.$axios.post(deleteURL, postData, this.postHeader).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'commentMgrForm',
        query: {
          id: row.id,
          neighborId: row.neighborId
        }
      })
    },
    async batchDelete (selected) {
      let isOk = await this.$confirm('确认删除？')
      if (isOk) {
        let res = await this.$axios.post(deleteBatchURL, {
          ids: selected.ids.join(',')
        })
        if (res.status - 0 === 100) {
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
